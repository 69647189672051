import { render, staticRenderFns } from "./thu-nhap-van-phong.vue?vue&type=template&id=541e4479&scoped=true&"
import script from "./thu-nhap-van-phong.vue?vue&type=script&lang=js&"
export * from "./thu-nhap-van-phong.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "541e4479",
  null
  
)

export default component.exports