<template>
    <div class="row">
        <div class="col-xl-12">
            <div id="panel-1" class="panel">
                <div class="panel-container show">
                    <div class="panel-content">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="24" :md="8" :lg="4" :xl="4">
                                <el-input type="text" size="medium" v-model="form.name" class=" mt-3"
                                          placeholder="Tên, mã hoặc số điện thoại nhân viên" autocomplete="false" ></el-input>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="6" :lg="3" :xl="3">
                                <el-select v-model="form.accountType" clearable class="w-100 mt-3" placeholder="Chọn loại tài khoản">
                                    <el-option
                                        v-for="item in optionAccountType"
                                        :key="item.name"
                                        :label="item.value"
                                        :value="item.name">
                                        <span style="float: left">{{ item.value }}</span>
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="6" :lg="3" :xl="3">
                                <el-date-picker class="w-100 mt-3"
                                                v-model="form.month"
                                                type="month"
                                                format="yyyy-MM"
                                                value-format="yyyy-MM"
                                                placeholder="Chọn tháng">
                                </el-date-picker>
                            </el-col>

                            <el-col :xs="24" :sm="8" :md="4" :lg="3" :xl="2">
                                <button :disabled="loading.search"
                                        class="btn btn-primary w-100 mt-3"
                                        @click="find">
                                    <i v-bind:class="[loading.search ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                                </button>
                            </el-col>
                        </el-row>
                        <el-table
                            :data="tableData"
                            stripe
                            v-loading="loading.table"
                            border
                            class="table-hover mb-5 mt-5 table-responsive-lg"
                            style="width: 100%">
                            <el-table-column label="#" type="index"  width="50" :index="indexMethod">
                            </el-table-column>
                            <el-table-column
                                prop="code"
                                label="Mã nhân sự"
                                width="100">
                                <template v-slot="scope">
                                    {{ scope.row.id  }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="Họ và tên">
                                <!--eslint-disable-->
                                <template slot-scope="scope">
                                    {{ scope.row.name }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="account_type"
                                label="Vị trí" >
                                <template slot-scope="scope">
                                    {{ scope.row.account_type }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="phone"
                                label="Số điện thoại">
                                <template slot-scope="scope">
                                    {{ scope.row.phone }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="phone"
                                label="Thời gian">
                                <template slot-scope="scope">
                                    {{ scope.row.time }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="phone"
                                label="Thu nhập">
                                <template slot-scope="scope">
                                   <b> {{ scope.row.salary | formatVND }}</b>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="edutalk-paging">
                            <div class="block">
                                <el-pagination
                                    background
                                    @current-change="handleCurrentChange"
                                    :current-page.sync="paging.current_page"
                                    :page-size="20"
                                    layout="prev, pager, next"
                                    :total="paging.total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {PAY_SLIP_CENTER, PAY_SLIP_CENTER_EXPORT} from '@/core/services/store/service.module';
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {accountTypeOption} from "@/core/config/accountTypeOption";

export default {
    name: "thu-nhap-van-phong",
    data() {
        return {
            loading: {
                search: false
            },
            tableData: [],
            paging: {
                current_page: 1,
                per_page: 10,
                total: 0,
            },
            form: {
                name: null,
                accountType: null,
                month: null,
            },
            optionAccountType: accountTypeOption,
            canExport : true,
        }
    },
    mounted() {
        let payload = [
            {title: "Thu nhập" },
            {title: "Thu nhập văn phòng", icon: 'far fa-user'},
        ];
        if (this.canExport){
            payload.push([{text : 'Export thu nhập theo tháng', 'cb' : this.exportExcel, 'class' : 'btn btn-success float-right'}]);
        }
        this.$store.dispatch(SET_BREADCRUMB, payload);
        this.querySever()
    },
    methods: {
        indexMethod(index) {
            return 10 * (this.paging.current_page - 1) + (index + 1);
        },
        find() {
            this.querySever();
        },
        exportExcel() {
            let params = this.mergeParams();
            this.$store.dispatch(PAY_SLIP_CENTER_EXPORT, params);
        },
        mergeParams(customProperties) {
            let params = {
                page: this.paging.current_page,
                per_page: this.paging.per_page,
            };
            if (this.form.name) {
                params = _.merge(params, {key_word: this.form.name })
            }
            if (this.form.accountType) {
                params = _.merge(params, {account_type: this.form.accountType })
            }
            if (this.form.month) {
                params = _.merge(params, {month: this.form.month})
            }
            params = _.merge(params, customProperties);
            return params;
        },
        querySever(customProperties) {
            this.loading = true;
            let params = this.mergeParams(customProperties);
            this.$store.dispatch(PAY_SLIP_CENTER, params).then((response) => {
                if (response.status === 422) {
                    this.$message({
                        type: 'warning',
                        message: `${response.message}`,
                        showClose: true,
                    });
                } else {
                    this.tableData = response.data;
                    this.paging = {
                        total: response.pagination ? response.pagination.total : '',
                        count: response.pagination ? response.pagination.total : '',
                        per_page: response.pagination ? response.pagination.per_page : '',
                        current_page: response.pagination ? response.pagination.current_page : '',
                        last_page: response.pagination ? response.pagination.last_page : '',
                    }
                }
                this.loading = false;
            });
        },
        handleCurrentChange(page) {
            this.querySever({ page : page});
        },
    },
    computed: {
        ...mapGetters(['currentUser'])
    },
}
</script>

<style scoped>

</style>